// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Eye.css */
.eye-grid {
    display: grid;
    grid-template-columns: repeat(10, 5px); /* 10 columns, 20px each */
    grid-template-rows: repeat(10, 5px);    /* 10 rows, 20px each */
    gap: 2px;
  }
  
  .pixel {
    width: 5px;
    height: 5px;
    background-color: #fff;
  }
  
  .pixel.filled {
    background-color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/Misc Components/EyeSeeYou.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,sCAAsC,EAAE,0BAA0B;IAClE,mCAAmC,KAAK,uBAAuB;IAC/D,QAAQ;EACV;;EAEA;IACE,UAAU;IACV,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* src/Eye.css */\n.eye-grid {\n    display: grid;\n    grid-template-columns: repeat(10, 5px); /* 10 columns, 20px each */\n    grid-template-rows: repeat(10, 5px);    /* 10 rows, 20px each */\n    gap: 2px;\n  }\n  \n  .pixel {\n    width: 5px;\n    height: 5px;\n    background-color: #fff;\n  }\n  \n  .pixel.filled {\n    background-color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
