export const pages = [
    {
        name:"home",
        label:"Home"
    },
    {
        name: "about",
        label: "About",
    }, 
    {
        name: "projects",
        label: "Recent Projects"
    },
    {
        name: "contact",
        label: "Contact"
    }
];